































import Vue, { VueConstructor } from 'vue';
import FormCard from '@/components/card/FormCard.vue';
import FieldPassword from '@/components/fields/FieldPassword.vue';
import ApiError from '@/components/ApiError.vue';
import Btn from '@/components/buttons/Btn.vue';
import notify from '@/util/notify';
import formSubmit from '@/mixins/formSubmit';
import { FormWrapperRequest } from '@/types/form';

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'ForgotPasswordSetNew',
  components: {
    Btn,
    FieldPassword,
    FormCard,
    ApiError,
  },
  mixins: [formSubmit],
  computed: {
    request(): FormWrapperRequest {
      return {
        config: {
          method: 'put',
          url: '/password/recover',
          data: this.model,
        },
      };
    },
  },
  data() {
    return {
      model: {
        password: '',
        confirm: '',
        code: '',
        id: '',
      },
    };
  },
  methods: {
    async onSubmit() {
      this.model.id = this.$route.params.id;
      this.model.code = this.$route.params.code;
      await this.submit(this.request, this.$refs.form);

      if (this.hasError) {
        return;
      }

      await this.$router.replace('/login');
      notify.success('Password successfully changed');
    },
  },
});
