














import Vue from 'vue';
import ApiService from '@/services/api.service';
import FormCard from '@/components/card/FormCard.vue';
import LinkBtn from '@/components/buttons/LinkBtn.vue';

export default Vue.extend({
  name: 'InvitationAction',
  components: { LinkBtn, FormCard },
  computed: {
    isAuthenticated(): boolean {
      return this.$auth.isAuthenticated();
    },
    invitationAccepted(): boolean {
      return this.$route.params.action === 'accept';
    },
  },
  data(): any {
    return {
      title: '',
      description: '',
      linkText: '',
    };
  },
  methods: {
    setDisplayInfo(action: string, error?: string) {
      const organisation = this.$route.query.organisation || '';
      if (error) {
        this.title = `A problem occurred while trying to ${action} the invitation.`;
        this.description = error;
        this.linkText = '';
        return;
      }

      if (this.invitationAccepted) {
        this.title = `The invitation to join ${organisation} has been accepted.`;
        this.description = 'If you\'re not automatically redirected in 5 seconds, please click the link below.';
        if (this.isAuthenticated) {
          this.linkText = 'Go to your homepage.';
        } else {
          this.linkText = 'Login to see your homepage.';
        }
      } else {
        this.title = `The invitation to join ${organisation} has been declined.`;
        this.description = 'No further action is required.';
        this.linkText = '';
      }
    },
    async updateStatusAndRedirect(id: string, action: string) {
      try {
        await ApiService.put(`/user/invite/${id}/${action}`);
        this.setDisplayInfo(action);

        if (this.invitationAccepted) {
          setTimeout(async () => this.redirectUser(), 5000);
        }
      } catch (e: any) {
        this.setDisplayInfo(action, e.message);
      }
    },
    // if the currently logged-in user isn't the same as the one who just accepted
    // the invitation log that one out and prompt the new one to log in again
    async redirectUser() {
      await this.$router.replace(this.isAuthenticated ? '/' : '/login');
    },
  },
  async created() {
    const { action, id } = this.$route.params;
    if (action !== 'accept' && action !== 'decline') {
      await this.redirectUser();
      return;
    }

    await this.updateStatusAndRedirect(id, action);
  },
});
