





















































import Vue from 'vue';
import ApiService from '@/services/api.service';
import FormCard from '@/components/card/FormCard.vue';
import FieldPassword from '@/components/fields/FieldPassword.vue';
import FieldLabel from '@/components/fields/FieldLabel.vue';
import ApiError from '@/components/ApiError.vue';
import formSubmit from '@/mixins/formSubmit';
import { FormWrapperRequest } from '@/types/form';
import Btn from '@/components/buttons/Btn.vue';
import EditInfoBanner from '@/components/profile/edit/EditInfoBanner.vue';

type InvitationDetails = {
  orgLabel: string;
  email: string;
}

function getDefaultDetails(): InvitationDetails {
  return {
    orgLabel: '',
    email: '',
  };
}

export default Vue.extend({
  name: 'Register',
  components: {
    FormCard,
    FieldPassword,
    FieldLabel,
    ApiError,
    EditInfoBanner,
    Btn,
  },
  mixins: [formSubmit],
  data(): any {
    return {
      description: '',
      title: '',
      valid: false,
      invitationDetails: getDefaultDetails(),
      email: '',
      model: {
        forename: '',
        surname: '',
        displayName: '',
        password: '',
        confirmPassword: '',
      },
    };
  },
  async created() {
    try {
      this.invitationDetails = await ApiService.get(`/register/${this.$route.params.invitationId}`);
      this.setDisplayInfo();
    } catch (e: any) {
      this.setDisplayInfo(e.message);
    }
  },
  computed: {
    request(): FormWrapperRequest {
      return {
        config: {
          data: {
            forename: this.model.forename,
            surname: this.model.surname,
            displayName: this.model.displayName,
            password: this.model.password,
            invitationId: this.$route.params.invitationId,
          },
          method: 'put',
          url: '/register',
        },
      };
    },
  },
  methods: {
    setDisplayInfo(err?: string) {
      if (err) {
        this.title = 'A problem occured.';
        this.description = err;
        this.valid = false;
      } else {
        this.title = `You have been invited to join ${this.invitationDetails.orgLabel}.`;
        this.description = 'Please complete the sign-up details to continue.';
        this.email = this.invitationDetails.email;
        this.valid = true;
      }

      this.title = `You have been invited to join ${this.invitationDetails.orgLabel}.`;
      this.description = 'Please complete the sign-up details to continue.';
      this.email = this.invitationDetails.email;
      this.valid = true;
    },
    async onSubmit() {
      await this.submit(this.request, this.$refs.form);

      if (this.hasError) {
        return;
      }

      this.notify(`You have successfully registered to ${this.invitationDetails.orgLabel}.`);
      await this.$router.replace('/login');
    },
  },
});
